<script>
  // Adapted from https://github.com/rodneylab/sveltekit-blog-mdx/blob/main/src/lib/components/SEO/Twitter.svelte

  export let twitterUsername;
  export let image;

  /*
   * When there is an equivalent og tag present, Twitter takes that so check OpenGraph before
   * adding additional tags, unless you want to override OpenGraph.
   */
</script>

<svelte:head>
  <meta content="summary_large_image" name="twitter:card" />
  {#if image}
    <meta name="twitter:image" content={image.url} />
  {/if}
  {#if twitterUsername}
    <meta name="twitter:creator" content={`@${twitterUsername}`} />
    <meta name="twitter:site" content={`@${twitterUsername}`} />
  {/if}
</svelte:head>
