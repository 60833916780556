<script>
  import Layout from '@/Layouts/Page.svelte'
  import Seo from "@/Components/Seo/Index.svelte";
  import {page, useForm} from "@inertiajs/svelte";
  import Button from "@/Components/Buttons/Button.svelte";
  import { fade } from 'svelte/transition';
  import {
    IconCoins,
    IconComment,
    IconFlame, IconGoogle,
    IconLinkedIn, IconMicrosoft,
    IconWWW,
  } from "@/Components/Assets/Icons/index.js";
  import Icon from "@/Components/Assets/Icon.svelte";
  import FAQ from "@/Components/Sections/FAQ.svelte";
  import Works from "@/Components/Sections/Works.svelte";
  import api from "@/Lib/api.js";
  import {failure} from "@/Lib/notices.js";
  import {getAuthUrl} from "@/Lib/auth.js";
  import Textarea from "@/Components/Forms/Textarea.svelte";
  import Loader from "@/Components/Elements/Loader.svelte";
  import Select from "@/Components/Forms/Select.svelte";
  import Label from "@/Components/Forms/Label.svelte";
  import Flatpickr from "svelte-flatpickr";
  import SelectBox from "@/Components/Forms/SelectBox.svelte";
  import Alert from "@/Components/Elements/Alert.svelte";
  import {afterUpdate} from "svelte";

  let submitted = false,
    availableDates = [],
    availableTimes = [],
    timezones = [],
    processing = false,
    meetingFormActive = false,
    responseFormActive = false,
    formElement,
    // calendarConnected = false,

    googleAuthUrl = '',
    microsoftAuthUrl = '',
    linkedinAuthUrl = '',
    emailAuthUrl = '',

    form = useForm({
      email: $page.props?.buyer?.email,
      reason: '',
      response_body: '',
      meeting_required: false,
      meeting_date: '',
      meeting_date_formatted: '',
      meeting_time: '',
      meeting_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone ?? '',
    });

  afterUpdate(() => {
    formElement?.scrollIntoView({ behavior: 'smooth' });
  });

  const updateTimezone = async (timezone = '') => {
    processing = true;
    availableTimes = []
    availableDates = []

    if (timezone === '') {
      timezone = $form.meeting_timezone;
    }

    return api.post(`/c/${$page.props?.campaign_link?.id}/availability?timezone=${timezone}`)
      .then((res) => res.data)
      .then((res) => {
        if (res?.slots) {
          availableDates = res.slots;
          timezones = res.timezones;
          // calendarConnected = res.connected;

          if ($form.meeting_date && $form.meeting_date instanceof Date && !isNaN($form.meeting_date)) {
            const dateStr = $form.meeting_date.toISOString().split('T')[0];
            if (dateStr && dateStr in availableDates) {
              availableTimes = availableDates[dateStr];
            }
          }
        }

        processing = false;
      })
      .catch((e) => {
        console.log(e);
        failure(e.message)
        processing = false;
      });
  },

  updateTimezoneEvent = async (event) => {
    await updateTimezone(event.detail.value);
  },

  setTime = (time) => {
    $form.setStore('meeting_time', time);
  },

  showMeetingForm = async (event) => {
    event.preventDefault();
    await updateTimezone().then(() => meetingFormActive = true);
  },

  handleSubmit = (event) => {
    event.preventDefault();

    processing = true;

    let data = $form.data();
    data = Object.assign(data, {
      meeting_required: meetingRequired,
      email: $page.props?.buyer?.email,
      meeting_timezone: data.meeting_timezone.value
    });

    api.post(`/c/${$page.props?.campaign_link?.id}/respond`, data)
      .then((res) => res.data)
      .then((res) => {
        $form.reset();
        processing = false;

        googleAuthUrl = getAuthUrl(`/auth/google/redirect?pr=${res.buyer_id}`);
        microsoftAuthUrl = getAuthUrl(`/auth/microsoft/redirect?pr=${res.buyer_id}`);
        linkedinAuthUrl = getAuthUrl(`/auth/linkedin/redirect?pr=${res.buyer_id}`);
        emailAuthUrl = getAuthUrl(`/auth/login?pr=${res.buyer_id}`);

        submitted = true;
      })
      .catch(error => {
        processing = false;

        if (error.response.status === 422) {
          $form.setError(error.response.data.errors);
        } else {
          failure(error.message)
          console.error(error);
        }
      });
  };

  $: followUpQuestion = $page.props?.questions && $form.reason ? $page.props?.questions[$form.reason] : null;
  $: meetingRequired = $form.reason === "I'm interested. Let's book a meeting via Pitchfire.";
  $: responseValid = $form.reason && $form.response_body.length >= 50;
  $: responseAllowed = (!meetingRequired && !meetingFormActive && $form.reason && $form.response_body.length >= 50)
    || (meetingRequired && meetingFormActive && $form.reason && $form.response_body.length >= 50 && $form.meeting_date && $form.meeting_time && $form.meeting_timezone);
</script>

<Seo title="Review Pitch" />

<Layout nav footer class="!max-w-6xl !pt-0">
  <div class="wrap">
    <div class="profile">
      <div class="employee">
        <div class="head">
          {#if $page.props?.seller?.avatar}
            <a class="p-img" href={`/@${$page.props?.seller?.slug}`} target="_blank" rel="noopener noreferrer">
              <img decoding="async" src={$page.props?.seller?.avatar} alt={$page.props?.seller?.name} />
            </a>
          {/if}

          <div class="p-info">
            {#if $page.props?.seller?.name}
              <h1>{$page.props?.seller?.name}</h1>
            {/if}

            {#if $page.props?.seller?.job_level || $page.props?.seller?.job_title}
              <p>{$page.props?.seller?.job_title ?? $page.props?.seller?.job_level}{$page.props?.seller?.org_name ? ` at ${$page.props?.seller?.org_name}` : ''}</p>
            {:else}
              <p>{$page.props?.seller?.org_name ?? ''}</p>
            {/if}
          </div>
        </div>

        {#if $page.props?.seller?.about}
          <div class="field">
            <h3>About me</h3>
            <p>{$page.props?.seller?.about}</p>
          </div>
        {/if}

        <div class="mobile-cta">
          <div>
            <h4>Review my pitch.</h4>
            <p>You get paid <span>${$page.props?.campaign_link?.amount}</span> 😊</p>
          </div>

          <div class="actions-wrap">
            <div class="actions">
              <a class="btn-green" href="#pitch">Review</a>
            </div>
          </div>
        </div>

        {#if $page.props?.seller?.linkedin_url}
          <div class="field li">
            <a href={$page.props?.seller?.linkedin_url} target="_blank" rel="noopener noreferrer">
              <Icon size="xxl" data={IconLinkedIn} />
            </a>

            <p><a href={$page.props?.seller?.linkedin_url} target="_blank" rel="noopener noreferrer">
              <span>in/{$page.props?.seller?.linkedin_url.replace("https://www.linkedin.com/in/", "").replace("http://www.linkedin.com/in/", "")}</span>
            </a></p>
          </div>
        {/if}

        {#if $page.props?.seller?.job_title || $page.props?.seller?.job_level}
          <div class="field">
            <h3>My Position in the Company</h3>
            <p>{$page.props?.seller?.job_title ? $page.props?.seller?.job_title : $page.props?.seller?.job_level}</p>
          </div>
        {/if}

        {#if $page.props?.seller?.department}
          <div class="field">
            <h3>My Department</h3>
            <p>{$page.props?.seller?.department}</p>
          </div>
        {/if}
      </div>

      <div class="company">
        <div class="wrap-info">
          {#if $page.props?.seller?.org_logo || $page.props?.seller?.org_name}
            <a href={`/org/${$page.props?.seller?.org_slug}`} target="_blank" rel="noopener noreferrer">
              <img
                decoding="async"
                src={$page.props?.seller?.org_logo}
                alt={$page.props?.seller?.org_name}
              />
            </a>
          {/if}

          {#if $page.props?.seller?.org_name}
            <p><a href={`/org/${$page.props?.seller?.org_slug}`} target="_blank" rel="noopener noreferrer">{$page.props?.seller?.org_name}</a></p>
          {/if}
        </div>

        {#if $page.props?.seller?.org_country}
          <div class="field">
            <h3>Headquarters</h3>
            <p>{$page.props?.seller?.org_city ? $page.props?.seller?.org_city + ', ' + $page.props?.seller?.org_country : $page.props?.seller?.org_country}</p>
          </div>
        {/if}

        <div class="field">
          <a class="icon-link" href="https://{$page.props?.seller?.org_website}" target="_blank" rel="noopener noreferrer">
            <Icon data={IconWWW} size="xxl" />
            <span>{$page.props?.seller?.org_website}</span>
          </a>
        </div>

        {#if $page.props?.seller?.org_linkedin}
          <div class="field">
            <a class="icon-link" href={$page.props?.seller?.org_linkedin} target="_blank" rel="noopener noreferrer">
              <Icon data={IconLinkedIn} size="xxl" />
              <span>{$page.props?.seller?.org_linkedin.replace('http://www.linkedin.com/company/', '')}</span>
            </a>
          </div>
        {/if}
      </div>
    </div>

    <div class="pitch" id="pitch">
      <div class="header">
        <div>
          <h3 class="text-2xl mb-2">Hi {$page.props?.buyer?.name}</h3>
          <h4>Review my pitch.</h4>
          <p>You get paid <span>${$page.props?.campaign_link?.amount}</span> 😊</p>
        </div>

        {#if !responseFormActive && !submitted}
          <div transition:fade={{ duration: 500 }}>
            <Button color="secondary" on:click={() => responseFormActive = true}>Respond</Button>
          </div>
        {/if}
      </div>

      <!-- eslint-disable-next-line svelte/no-at-html-tags -->
      <div class="body">{@html $page.props?.campaign_link?.pitch}</div>

      {#if submitted}
        <Alert type="success" title="Response submitted" message="Your response has been submitted successfully, please login to access your funds." />

        <div class="login">
          <h2>Create your Pitchfire Account</h2>

          <div class="actions">
            <Button dynamic={false} href={googleAuthUrl} loading={googleAuthUrl === ''}>
              <Icon data={IconGoogle} class="fill-white" />
              <span>Continue with Google</span>
            </Button>

            <Button dynamic={false} href={microsoftAuthUrl} loading={microsoftAuthUrl === ''}>
              <Icon data={IconMicrosoft} class="fill-white" />
              <span>Continue with Microsoft</span>
            </Button>

            <Button dynamic={false} href={linkedinAuthUrl} loading={linkedinAuthUrl === ''}>
              <Icon data={IconLinkedIn} class="fill-white" />
              <span>Continue with LinkedIn</span>
            </Button>
          </div>

          <p><a href={emailAuthUrl} class:disabled={emailAuthUrl === ''}>Continue with Email</a></p>
        </div>
      {:else if responseFormActive}
        <div bind:this={formElement} class="actions-wrap" transition:fade={{ duration: 500 }}>
          <h4>How do you want to respond to {$page.props?.seller?.first_name}</h4>
          <p>Try and give {$page.props?.seller?.name} their <span>${$page.props?.campaign_link?.amount} worth</span> 😊</p>

          <div class="respond">
            <form method="post" on:submit={(event) => handleSubmit(event)}>
              {#if !meetingFormActive}
                <div>
                  <Select name="reason" label="Reason" size="sm"
                          placeholder="Select a reason" options={$page.props?.responses}
                          bind:value={$form.reason}
                          error={$form.errors.reason} />
                </div>

                {#if followUpQuestion}
                  <div>
                    <Label name="body" size="sm" label={followUpQuestion} />
                    <Textarea name="body" size="sm" placeholder="Answer here..."
                              maxLength="1000" rows="6" bind:value={$form.response_body}
                              error={$form.errors.response_body} />
                  </div>
                {/if}
              {:else}
                <div class="meeting">
                  {#if availableDates.length === 0}
                    {#if processing}
                      <Loader />
                     {:else}
                      <Alert type="warning" title="No availability">There was an error fetching availability, please contact <a href="mailto:support@pitchfire.com">support@pitchfire.com</a>.</Alert>
                    {/if}
                  {:else}
                    <p><strong>Let’s setup a time with {$page.props?.campaign_link?.seller?.name}.</strong></p>
                    <div class="relative z-10">
                      <SelectBox
                        id="timezone"
                        name="timezone"
                        label="Time Zone"
                        size="sm"
                        multiple={false}
                        placeholder="Select Timezone"
                        options={timezones}
                        bind:value={$form.meeting_timezone}
                        error={$form.errors.meeting_timezone}
                        on:change={(event) => updateTimezoneEvent(event)}
                        on:clear={(event) => updateTimezoneEvent(event)}
                      />
                    </div>

                    {#if $form.errors.meeting_date}
                      <div class="my-2 text-red-600 text-xs">{$form.errors.meeting_date}</div>
                    {/if}
                    {#if $form.errors.meeting_time}
                      <div class="my-2 text-red-600 text-xs">{$form.errors.meeting_time}</div>
                    {/if}

                    <div class="form-row">
                      <div>
                        <Label name="date" size="sm" label="Select Date" />
                        <Flatpickr
                          name="date"
                          bind:value={$form.meeting_date}
                          bind:formattedValue={$form.meeting_date_formatted}
                          options={{
                              inline: true,
                              enable: Object.keys(availableDates),
                              altInputClass: 'hidden',
                              altInput: true,
                              onChange(selectedDates, dateStr) {
                                availableTimes = availableDates[dateStr]
                              }
                            }}
                        />
                      </div>

                      <div class="times">
                        <Label name="time" size="sm" label="Select a time" />
                        {#if $form.meeting_date}
                          <div>
                            {#each availableTimes as time}
                              <Button
                                type="button"
                                size="sm"
                                color="tertiary"
                                class="relative"
                                disabled={$form.processing || processing}
                                on:click={() => setTime(time)}
                              >
                                {#if time === $form.meeting_time}
                                  <span class="bull"></span>
                                {/if}
                                <span>{time}</span>
                              </Button>
                            {:else}
                              <p>No time slots available</p>
                            {/each}
                          </div>
                        {:else}
                          <p>Please select a date</p>
                        {/if}

                        {#if $form.processing || processing}
                          <Loader />
                        {/if}
                      </div>
                    </div>
                  {/if}
                </div>
              {/if}

              <div class="actions">
                {#if meetingRequired && meetingFormActive}
                  <Button
                    color="tertiary"
                    size="sm"
                    type="button"
                    on:click={() => meetingFormActive = false}>Back</Button>
                {/if}
                {#if meetingRequired && !meetingFormActive}
                    <Button
                      color="secondary"
                      size="sm"
                      type="button"
                      loading={$form.processing || processing}
                      disabled={!responseValid}
                      on:click={(event) => showMeetingForm(event)}>Next</Button>
                {/if}
                <Button color="secondary"
                        size="sm"
                        disabled={!responseAllowed}
                        loading={$form.processing}
                        on:click={(event) => handleSubmit(event)}>Respond</Button>
              </div>
            </form>
          </div>
        </div>
      {:else}
        <div class="flex flex-col items-end">
          <div class="mb-2">
            <Button color="secondary" on:click={() => responseFormActive = true}>Respond</Button>
          </div>

          <p class="m-0">You get paid <span class="text-violet font-semibold">${$page.props?.campaign_link?.amount}</span> 😊</p>
        </div>
      {/if}
    </div>
  </div>

  <section id="how-it-works" class="how">
    <Works
      title="How does Pitchfire work?"
      cards={[{
        image: IconComment,
        title: "Answer this pitch truthfully",
        description:
          "<p>It doesn’t matter if the answer is yes or no.</p>" +
          "<p>If you are interested, you can book a meeting right on their calendar.</p>" +
          "<p>If you aren’t, just be kind and let them know why.</p>",
      }, {
        image: IconCoins,
        title: "Get paid for answering the pitch",
        description: "<p>This company pitching you believes it’s more ethical to pay you for your attention and response on their sales pitch, then spam you cold calls, emails, and LinkedIn messages.</p>" +
          "<p>Remember they are paying for an answer, so try and be as helpful as possible.</p>",
      }, {
        image: IconFlame,
        title: "Join Pitchfire to withdraw money",
        description:
          "<p>Pitchfire is the world’s leading paid prospecting platform.</p>" +
          "<p>All people who join Pitchfire answer sales pitches from businesses for money, and securely transfer them to their bank.</p>" +
          "<p>All members list themselves in our B2B marketplace, allowing other businesses to pitch them for responding.</p>" +
          "<p>We’ll ask you to join to receive the money.</p>",
      }]}
    />
    <FAQ class="mt-12" />
  </section>
</Layout>

<style lang="postcss">
  .wrap {
    @apply relative mb-20 min-h-full lg:grid lg:grid-cols-5 gap-0;
  }

  .mobile-cta {
    @apply flex sm:hidden mt-4 mb-8 bg-white px-4 py-3 rounded-lg gap-4 justify-between;

    p {
      @apply m-0;
    }
  }

  .profile {
    @apply flex flex-col sm:flex-row lg:flex-col py-5 px-5 md:px-7 relative bg-grad-tertiary gap-4 lg:col-span-2;

    .employee {
      @apply w-full sm:w-3/5 md:w-2/3 lg:w-full;

      .head {
        @apply flex flex-row items-center gap-4 sm:gap-6;
      }
    }

    .p-info {
      h1 {
        @apply text-3xl text-grey-800 mb-1;

        a {
          @apply hover:underline no-underline;
        }
      }

      p {
        @apply text-sm text-grey mb-1;
      }
    }

    .p-img img {
      @apply w-20 h-20 border-grey-200 rounded-lg object-cover object-center;
    }

    .li {
      @apply w-full flex items-center gap-2;

      img {
        @apply w-10 h-10 object-contain object-center mr-2 rounded-lg;
      }

      p {
        @apply mb-0 text-sm;
      }
    }

    .field {
      @apply mt-5;

      h3 {
        @apply text-xs text-black mb-1 uppercase;
      }

      p {
        @apply text-sm text-grey-800 font-normal mb-0 mt-2;
      }
    }

    .company {
      @apply mt-4 sm:mt-8 lg:mt-12;
    }

    .wrap-info {
      @apply w-full flex items-center gap-2;

      img {
        @apply w-10 h-10 object-contain object-center mr-2 rounded-lg;
      }

      p {
        @apply mb-0 text-sm;
      }
    }

    .icon-link {
      @apply flex items-center text-sm no-underline hover:underline;

      span {
        @apply ml-2 block;
      }
    }
  }

  .header {
    @apply flex justify-between;

    h4 {
      @apply font-semibold inline-block leading-snug text-base;
    }

    p {
      @apply m-0;

      span {
        @apply text-violet font-semibold;
      }
    }
  }

  .pitch {
    @apply bg-white px-6 py-5 md:px-10 md:py-8 lg:col-span-3;

    .body {
      @apply border border-grey-200 rounded-lg p-4 text-grey-700 text-base my-4;
    }

    .actions-wrap {
      @apply flex flex-col items-start;

      h4 {
        @apply font-semibold inline-block leading-snug text-sm;
      }

      & > p {
        @apply m-0;

        span {
          @apply text-violet font-semibold;
        }
      }
    }
  }

  .respond {
    @apply w-full mt-4;

    p {
      @apply text-sm mb-4;

      span {
        @apply text-violet font-semibold;
      }
    }

    form {
      @apply flex flex-col gap-4;

      .actions {
        @apply mb-4 justify-end gap-4 flex;
      }
    }
  }

  .meeting {
    @apply w-full flex flex-col gap-4;

    & > p {
      @apply text-sm mb-0;
    }

    .form-row {
      @apply flex flex-row flex-wrap gap-4 w-full;
    }

    .times {
      @apply block grow;

      div {
        @apply grid grid-cols-2 gap-x-4 gap-y-2;
      }
    }

    .bull {
      @apply absolute before:content-["*"] left-1.5 top-1;
    }
  }

  .login {
    @apply mb-12 flex flex-col items-center gap-4;

    h2 {
      @apply text-2xl font-semibold mb-4 mt-12 text-center;
    }

    .actions {
      @apply flex flex-wrap justify-center items-center gap-4;

      span {
        @apply sm:text-nowrap;
      }
    }

    p {
      @apply text-center mt-4 text-sm;
    }
  }
</style>
