<script>
  import Layout from '@/Layouts/Page.svelte'
  import Seo from "@/Components/Seo/Index.svelte";
  import {page, useForm} from "@inertiajs/svelte";
  import { fade } from 'svelte/transition';
  import Profile from "@/Components/Elements/Profile.svelte";
  import Button from "@/Components/Buttons/Button.svelte";
  import TagGroup from "@/Components/Elements/TagGroup.svelte";
  import Alert from "@/Components/Elements/Alert.svelte";
  import Select from "@/Components/Forms/Select.svelte";
  import Label from "@/Components/Forms/Label.svelte";
  import Textarea from "@/Components/Forms/Textarea.svelte";
  import Flatpickr from "svelte-flatpickr";
  import SelectBox from "@/Components/Forms/SelectBox.svelte";
  import Loader from "@/Components/Elements/Loader.svelte";
  import api from "@/Lib/api.js";
  import {failure} from "@/Lib/notices.js";
  import Icon from "@/Components/Assets/Icon.svelte";
  import {IconGoogle, IconLinkedIn, IconMicrosoft} from "@/Components/Assets/Icons/index.js";
  import {onMount} from "svelte";
  import {getAuthUrl} from "@/Lib/auth.js"

  let submitted = false,
   availableDates = $page.props?.slots,
   availableTimes = [],
   processing = false,

   googleAuthUrl = '',
   microsoftAuthUrl = '',
   linkedinAuthUrl = '',
   emailAuthUrl = '',

   form = useForm({
    reason: '',
    response_body: '',
    meeting_required: meetingFormActive,
    meeting_date: '',
    meeting_date_formatted: '',
    meeting_time: '',
    meeting_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone ?? '',
  });

  const updateTimezone = async (timezone = '') => {
    processing = true;
    availableTimes = []
    availableDates = []

    if (timezone === '') {
      timezone = $form.meeting_timezone;
    }

    api.post(`/p/${$page.props.pitch.id}/availability?timezone=${timezone}`)
      .then((res) => res.data)
      .then((res) => {
        processing = false;
        availableDates = res.slots

        if ($form.meeting_date && $form.meeting_date instanceof Date && !isNaN($form.meeting_date)) {
          const dateStr = $form.meeting_date.toISOString().split('T')[0];
          if (dateStr && dateStr in availableDates) {
            availableTimes = availableDates[dateStr];
          }
        }
      })
      .catch((e) => {
        processing = false;
        failure(e.message)
      });
  },

   updateTimezoneEvent = async (event) => {
    await updateTimezone(event.detail.value);
  },

   setTime = (time) => {
    $form.setStore('meeting_time', time);
  },

   handleSubmit = (event) => {
    event.preventDefault();

    $form
      .transform((data) => {
        const timezone = data.meeting_timezone.value;
        return {
          ...data,
          meeting_required: meetingFormActive,
          meeting_timezone: timezone
        };
      })
      .post(`/p/${$page.props?.pitch?.id}/respond`, {
        onSuccess: () => {
          $form.reset();
          submitted = true;
        }
      })
  }

  onMount(() => {
    googleAuthUrl = getAuthUrl(`/auth/google/redirect?pr=${  $page.props?.pitch?.buyer?.id}`);
    microsoftAuthUrl = getAuthUrl(`/auth/microsoft/redirect?pr=${  $page.props?.pitch?.buyer?.id}`);
    linkedinAuthUrl = getAuthUrl(`/auth/linkedin/redirect?pr=${  $page.props?.pitch?.buyer?.id}`);
    emailAuthUrl = getAuthUrl(`/auth/login?pr=${  $page.props?.pitch?.buyer?.id}`);
  });

  $: followUpQuestion = $page.props?.questions && $form.reason ? $page.props?.questions[$form.reason] : null;
  $: meetingFormActive = $form.reason === "I'm interested. Let's book a meeting via Pitchfire.";
  $: responseAllowed = (!meetingFormActive && $form.reason && $form.response_body.length >= 50)
    || (meetingFormActive && $form.reason && $form.response_body.length >= 50 && $form.meeting_date && $form.meeting_time && $form.meeting_timezone);
</script>

<Seo title="Review Pitch" />

<Layout nav footer>
  <div class="wrap">
    <div class="book">
      <div class="header">
        <Profile
          title={`Hi! I'm ${$page.props?.pitch?.seller?.name}`}
          titleElement="h1"
          subTitleHtml={$page.props?.pitch?.seller?.description}
          subTitleElement="h2"
          image={$page.props?.pitch?.seller?.avatar}
          imageAlt={$page.props?.pitch?.seller?.name}
          subImage={$page.props?.pitch?.seller?.org_logo}
          subImageAlt={$page.props?.pitch?.seller?.org_name}
          size="xl"
        >
          {#if $page.props.focuses?.length > 0}
            <TagGroup tags={$page.props.focuses.map(x => ({ title: x.name }))} />
          {/if}

          <div slot="actions" class="amount">
            ${$page.props?.pitch?.request_fee}
          </div>
        </Profile>
      </div>

      <div class="pitch">
        <h4>Review my pitch.</h4>

        <!-- eslint-disable-next-line svelte/no-at-html-tags -->
        <div class="body">{@html $page.props?.pitch?.body}</div>

        {#if $page.props?.error || $page.props?.warning}
          <Alert type={$page.props?.error ? 'error' : 'warning'} title={$page.props.error ?? $page.props?.warning} />
        {:else if submitted || $page.props?.pitch?.status === 'Accepted' || $page.props?.pitch?.status === 'Rejected'}
          <Alert type="success" title="Response submitted" message="Your response has been submitted successfully, please login to access your funds." />

          <div class="login">
            <h2>Create your Pitchfire Account</h2>

            <div class="actions">
              <Button dynamic={false} href={googleAuthUrl} loading={googleAuthUrl === ''}>
                <Icon data={IconGoogle} class="fill-white" />
                <span>Continue with Google</span>
              </Button>

              <Button dynamic={false} href={microsoftAuthUrl} loading={microsoftAuthUrl === ''}>
                <Icon data={IconMicrosoft} class="fill-white" />
                <span>Continue with Microsoft</span>
              </Button>

              <Button dynamic={false} href={linkedinAuthUrl} loading={linkedinAuthUrl === ''}>
                <Icon data={IconLinkedIn} class="fill-white" />
                <span>Continue with LinkedIn</span>
              </Button>
            </div>

            <p><a href={emailAuthUrl} class:disabled={emailAuthUrl === ''}>Continue with Email</a></p>
          </div>
        {:else}
          <div class="actions-wrap">
            <h4>How do you want to respond to {$page.props?.pitch?.seller?.name}</h4>
            <p>Try and give {$page.props?.pitch?.seller?.name} their <span>${$page.props?.pitch?.request_fee} worth</span> 😊</p>

            <div class="respond">
              <form method="post" on:submit={(event) => handleSubmit(event)}>
                <div>
                  <Select name="reason" label="Reason" size="sm"
                          placeholder="Select a reason" options={$page.props?.responses}
                          bind:value={$form.reason}
                          error={$form.errors.reason} />
                </div>

                {#if followUpQuestion}
                  <div>
                    <Label name="body" size="sm" label={followUpQuestion} />
                    <Textarea name="body" size="sm" placeholder="Answer here..."
                              maxLength="1000" rows="6" bind:value={$form.response_body}
                              error={$form.errors.response_body} />
                  </div>
                {/if}

                {#if meetingFormActive}
                  <div class="meeting" transition:fade={{ duration: 700 }}>
                    <p><strong>Let’s setup a time with {$page.props?.pitch?.seller?.name}.</strong></p>
                    <div class="relative z-10">
                      <SelectBox
                        id="timezone"
                        name="timezone"
                        label="Time Zone"
                        size="sm"
                        multiple={false}
                        placeholder="Select Timezone"
                        options={$page.props?.timezones}
                        bind:value={$form.meeting_timezone}
                        error={$form.errors.meeting_timezone}
                        on:change={(event) => updateTimezoneEvent(event)}
                        on:clear={(event) => updateTimezoneEvent(event)}
                      />
                    </div>

                    {#if $form.errors.meeting_date}
                      <div class="my-2 text-red-600 text-xs">{$form.errors.meeting_date}</div>
                    {/if}
                    {#if $form.errors.meeting_time}
                      <div class="my-2 text-red-600 text-xs">{$form.errors.meeting_time}</div>
                    {/if}

                    <div class="form-row">
                      <div>
                        <Label name="date" size="sm" label="Select Date" />
                        <Flatpickr
                          name="date"
                          bind:value={$form.meeting_date}
                          bind:formattedValue={$form.meeting_date_formatted}
                          options={{
                            inline: true,
                            enable: Object.keys(availableDates),
                            altInputClass: 'hidden',
                            altInput: true,
                            onChange(selectedDates, dateStr) {
                              availableTimes = availableDates[dateStr]
                            }
                          }}
                        />
                      </div>

                      <div class="times">
                        <Label name="time" size="sm" label="Select a time" />
                        {#if $form.meeting_date}
                          <div>
                            {#each availableTimes as time}
                              <Button
                                type="button"
                                size="sm"
                                color="tertiary"
                                class="relative"
                                disabled={$form.processing || processing}
                                on:click={() => setTime(time)}
                              >
                                {#if time === $form.meeting_time}
                                  <span class="bull"></span>
                                {/if}
                                <span>{time}</span>
                              </Button>
                            {:else}
                              <p>No time slots available</p>
                            {/each}
                          </div>
                        {:else}
                          <p>Please select a date</p>
                        {/if}

                        {#if $form.processing || processing}
                          <Loader />
                        {/if}
                      </div>
                    </div>
                  </div>
                {/if}

                <div class="actions">
                  <Button color="secondary"
                          size="sm"
                          disabled={!responseAllowed}
                          loading={$form.processing}
                          on:click={(event) => handleSubmit(event)}>Respond</Button>
                </div>
              </form>
            </div>
          </div>
        {/if}
      </div>
    </div>
  </div>
</Layout>

<style lang="postcss">
  .wrap {
    @apply relative mb-20 min-h-full;
  }

  .book {
    @apply border bg-white border-grey-200 shadow-lg rounded-2xl max-w-4xl mx-auto relative z-10;
  }

  .header {
    @apply bg-grey-100 border-b border-grey-200 px-6 py-4 md:px-10 md:py-8 rounded-t-2xl;

    .amount {
      @apply text-violet text-2xl font-semibold mb-2;
    }
  }

  .pitch {
    @apply px-6 py-4 md:px-10 md:py-8;

    h4 {
      @apply font-semibold inline-block leading-snug text-sm;
    }

    .body {
      @apply border border-grey-200 rounded-lg p-4 text-grey-700 text-base my-4;
    }

    .actions-wrap {
      @apply mt-4 flex flex-col;

      & > p {
        @apply text-sm mb-4;

        span {
          @apply text-violet font-semibold;
        }
      }
    }
  }

  .respond {
    @apply w-full;

    p {
      @apply text-sm mb-4;

      span {
        @apply text-violet font-semibold;
      }
    }

    form {
      @apply flex flex-col gap-4;

      .actions {
        @apply mb-4 justify-end gap-4 flex;
      }
    }
  }

  .meeting {
    @apply w-full flex flex-col gap-4;

    & > p {
      @apply text-sm mb-0;
    }

    .form-row {
      @apply flex flex-row flex-wrap gap-4 w-full;
    }

    .times {
      @apply block grow;

      div {
        @apply grid grid-cols-2 gap-x-4 gap-y-2;
      }
    }

    .bull {
      @apply absolute before:content-["*"] left-1.5 top-1;
    }
  }

  .login {
    @apply mb-12 flex flex-col items-center gap-4;

    h2 {
      @apply text-2xl font-semibold mb-4 mt-12 text-center;
    }

    .actions {
      @apply flex flex-wrap justify-center items-center gap-4;

      span {
        @apply sm:text-nowrap;
      }
    }

    p {
      @apply text-center mt-4 text-sm;
    }
  }
</style>
