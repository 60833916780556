<script>
  import Layout from "@/Layouts/Page.svelte"
  import Card from "@/Components/Layout/Card.svelte";
  import Button from "@/Components/Buttons/Button.svelte";
  import {useForm} from "@inertiajs/svelte";
  import Seo from "@/Components/Seo/Index.svelte";
  import Alert from "@/Components/Elements/Alert.svelte";
  import Input from "@/Components/Forms/Input.svelte";

  let success = false;

  const form = useForm({ email: '' }),

    handleSubmit = (event) => {
      event.preventDefault();
      $form.post('/unsubscribe/form/submit', {
        onSuccess: () => {
          $form.reset();
          success = true;
        }
      });
    }
</script>

<Seo title="Unsubscribe" />

<Layout nav footer>
  <main class="mx-auto px-4 py-8 container h-full max-w-7xl">
    <Card title="Unsubscribe" class="max-w-3xl mx-auto text-center">
      {#if success}
        <Alert type="success">
          <p class="m-0">You have successfully unsubscribed.</p>
        </Alert>
      {:else}

        <form method="post" class="mb-12 text-left" on:submit={(event) => handleSubmit(event)}>
          <p>Enter your email address to unsubscribe from Pitchfire and stop receiving pitches.</p>

          <Input
            type="email"
            name="email"
            bind:value={$form.email}
            bind:error={$form.errors.email}
            placeholder="Enter your email address"
            required
          />

          <div class="flex gap-4 mt-4">
            <Button type="submit" size="sm" color="red" on:click={(event) => handleSubmit(event)}>Unsubscribe</Button>
            <a href="https://www.pitchfire.com" size="sm" class="btn-green">Cancel</a>
          </div>
        </form>
      {/if}
    </Card>
  </main>
</Layout>
