<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" class={$$props.class}>
  <circle class="opacity-25 stroke-blue-100" cx="12" cy="12" r="10" stroke-width="4"></circle>
  <path class="opacity-75 fill-red" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
</svg>

<style lang="postcss">
  svg {
    @apply block w-8 h-8 fill-transparent flex-none animate-spin;
  }
</style>
