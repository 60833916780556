<script>
  import classNames from 'classnames';

  /** @type {string} name */
  export let name;
  export let label = '';
  export let labelHtml = '';

  /** @type {import('@/Lib/types/component.d').InputSizeType} size */
  export let size;
</script>

<!-- eslint-disable-next-line svelte/no-at-html-tags -->
<label {...$$restProps} class={classNames(size, $$props.class)} for={name}>{label}{@html labelHtml}<slot /></label>

<style lang="postcss">
  label {
    @apply w-full block text-base mb-1 text-grey-700;

    &.sm {
      @apply text-sm;
    }

    &.lg {
      @apply text-lg;
    }
  }
</style>
