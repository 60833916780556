<script>
  // Adapted from https://github.com/rodneylab/sveltekit-blog-mdx/blob/main/src/lib/components/SEO/OpenGraph.svelte

  export let image;
  export let metaDescription;
  export let ogLanguage;
  export let pageTitle;
  export let siteTitle;
  export let url;
</script>

<svelte:head>
  <meta content={siteTitle} property="og:site_name" />
  <meta content={ogLanguage} property="og:locale" />
  <meta content={url} property="og:url" />
  <meta content="website" property="og:type" />
  <meta content={pageTitle} property="og:title" />
  <meta content={metaDescription} property="og:description" />
  {#if image}
    <meta property="og:image" content={image.url} />
    <meta property="og:image:width" content="1200" />
    <meta property="og:image:height" content="627" />
    <meta property="og:image:alt" content={image.alt} />
  {/if}
</svelte:head>
