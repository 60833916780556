<script>
  import Layout from "@/Layouts/Page.svelte";
  import Works from '@/Components/Sections/Works.svelte';
  import SectionProfile from "@/Components/Sections/Profile.svelte";
  import FAQ from "@/Components/Sections/FAQ.svelte";
  import Modal from "@/Components/Elements/Modal.svelte";
  import {onMount} from "svelte";
  import Button from "@/Components/Buttons/Button.svelte";

  export let profile;

  let invited = false;

  onMount(() => {
    if (window.location.search.includes('utm_source=lead_invite')) {
      invited = true;
    }
  });
</script>

<Layout nav footer>
  <Modal open={invited} permanent>
    <div class="text-center">
      <div class="text-sm">
        <p>Hey there!</p>
        <p>You've been redirected to {profile?.first_name ? profile?.first_name + "'s" : 'this'} Pitchfire Page because you sent them a cold email.</p>
        <p>However, {profile?.first_name ?? profile?.name ?? 'they'} will only respond to pitches via Pitchfire.</p>
        <p>Click the button below to submit your pitch</p>
      </div>
      <Button color="primary" size="sm" on:click={() => invited = false}>continue</Button>
    </div>
  </Modal>

  <section class="profile">
    <SectionProfile {profile} class="bg-blue-100 py-5 md:py-6 !px-6 lg:!px-10" />
    <slot />
  </section>

  <section id="how-it-works" class="how">
    <Works title="How does Pitchfire work?" />
    <FAQ class="mt-12" />
  </section>
</Layout>

<style lang="postcss">
  .profile {
    @apply overflow-hidden rounded-xl;
  }

  .how {
    border-top: 3px solid;
    @apply mt-12 max-w-5xl lg:px-12 mx-auto pt-12 border-white;
  }
</style>
